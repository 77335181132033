<template>
  <div>
    <header-slot></header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'administration-report-monthly-payments-tab' }"
        :active="isActiveTab(1)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Monthly Payments
      </b-nav-item>
      <b-nav-item
        v-if="isCeo"
        :to="{ name: 'administration-report-global-income-tab' }"
        :active="isActiveTab(2)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Global Income
      </b-nav-item>
      <b-nav-item
        v-if="isCeo"
        :to="{ name: 'administration-report-payments-status-tab' }"
        :active="isActiveTab(3)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Payment Status
      </b-nav-item>
      <b-nav-item
        v-if="isCeo"
        :to="{ name: 'administration-report-vs-payment-tab' }"
        :active="isActiveTab(4)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        VS Payment
      </b-nav-item>
      <b-nav-item
        v-if="isCeo"
        :to="{ name: 'administration-report-general-balance-ds-tab' }"
        :active="isActiveTab(5)"
        :link-classes="['px-3', bgTabsNavs]"
      >
        General Balance DS
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    routerName() {
      return this.$route.matched[2].name;
    },
  },
  methods: {
    isActiveTab(tab) {
      if (
        tab == 1 &&
        this.routerName == "administration-report-monthly-payments-tab"
      ) {
        return true;
      } else if (
        tab == 2 &&
        this.routerName == "administration-report-global-income-tab"
      ) {
        return true;
      } else if (
        tab == 3 &&
        this.routerName == "administration-report-payments-status-tab"
      ) {
        return true;
      } else if (
        tab == 4 &&
        this.routerName == "administration-report-vs-payment-tab"
      ) {
        return true;
      } else if (
        tab == 5 &&
        this.routerName == "administration-report-general-balance-ds-tab"
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.bg-tab {
  background-color: #0090e7;
}
</style>
